import type { UseQueryOptions } from "@tanstack/vue-query";
import type { MaybeRef } from "@tanstack/vue-query/build/lib/types";
import { reserveerApi } from "@/lib/backend";

const apiCall = reserveerApi.persoonlijkeTrainingen.getTrainingsDag;
type ApiCall = typeof apiCall;
type Data = Awaited<ReturnType<ApiCall>>["data"];

export function queryOptions_personalTrainingDay(
	memberId: MaybeRef<Parameters<ApiCall>[0]>,
	programId: MaybeRef<Parameters<ApiCall>[1]>,
	programWeek: MaybeRef<Parameters<ApiCall>[2]>,
	programDay: MaybeRef<Parameters<ApiCall>[3]>,
): UseQueryOptions<
	Data,
	unknown,
	Data,
	readonly [
		"member",
		typeof memberId,
		"personal-trainings",
		typeof programId,
		"week",
		typeof programWeek,
		"day",
		typeof programDay,
	]
> {
	return {
		queryKey: [
			"member",
			memberId,
			"personal-trainings",
			programId,
			"week",
			programWeek,
			"day",
			programDay,
		] as const,
		queryFn: async (context) =>
			await apiCall(
				context.queryKey[1],
				context.queryKey[3],
				context.queryKey[5],
				context.queryKey[7],
				{ signal: context.signal },
			).then((response) => response.data),
	};
}
