
























































































































import { type PropType, computed, defineComponent, ref } from "@vue/composition-api";
import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { useStorage } from "@vueuse/core";
import deepmerge from "deepmerge";
import { z } from "zod";
import PlayCircleIcon from "@/lib/@heroicons/vue/24/outline/PlayCircleIcon.vue";
import { reserveerApi } from "@/lib/backend";
import type {
	IomodelsOefeningExtern,
	IomodelsTrainingsDagExtern,
	IomodelsTrainingsProgrammaVoortgangExtern,
} from "@/lib/backend/reserveer.api";
import { formatErrorMessage } from "@/lib/formatErrorMessage";
import { usePersonalTrainingData } from "@/lib/hooks/usePersonalTrainingData";
import { buttonVariants } from "@/lib/pfg/components/button";
import { inputVariants } from "@/lib/pfg/components/input";
import { cx } from "@/lib/style/cva.config";
import { toast } from "@/lib/vue2-sonner";
import { logger } from "@/logger";

const ExerciseValue = z.coerce.number().nullish();
const DaySchema = z.record(z.coerce.number(), ExerciseValue);
const StorageSchema = z.record(
	// Member ID
	z.coerce.number(),
	// Program record
	z.record(
		// Program ID
		z.coerce.number(),
		// Week record
		z.record(
			// Week number
			z.coerce.number(),
			// Day record
			z.record(
				// Day number
				z.coerce.number(),
				DaySchema,
			),
		),
	),
);

export default defineComponent({
	components: { PlayCircleIcon },
	props: {
		memberId: {
			type: Number,
			required: true,
		},
		programId: {
			type: Number,
			required: true,
		},
		programWeek: {
			type: Number,
			required: true,
		},
		programDay: {
			type: Number,
			required: true,
		},
		trainingDay: {
			type: Object as PropType<IomodelsTrainingsDagExtern>,
			required: true,
		},
	},
	setup(props, { root }) {
		const videoRef = ref<HTMLVideoElement | null>(null);
		const queryClient = useQueryClient();

		const personalTraining = usePersonalTrainingData(
			computed(() => props.memberId),
			computed(() => props.programId),
		);

		const { mutateAsync, isLoading } = useMutation({
			mutationKey: [
				"member",
				props.memberId,
				"personal-trainings",
				props.programId,
				"week",
				props.programWeek,
				"day",
				props.programDay,
			] as const,
			mutationFn: async (variables: IomodelsTrainingsProgrammaVoortgangExtern) =>
				await reserveerApi.persoonlijkeTrainingen.trainingAfronden(variables),
			onSuccess: () => {
				queryClient.invalidateQueries({
					queryKey: ["member", props.memberId, "personal-trainings", props.programId],
				});

				if (personalTraining.value.isLastDay) {
					root.$router.push({ name: "Member programs" });
				} else {
					root.$router.replace({
						params: {
							week: String(
								personalTraining.value.currentDay < personalTraining.value.daysPerWeek
									? personalTraining.value.currentWeek
									: personalTraining.value.currentWeek + 1,
							),
							day: String(
								personalTraining.value.currentDay < personalTraining.value.daysPerWeek
									? personalTraining.value.currentDay + 1
									: 1,
							),
						},
					});
				}
			},
		});

		const isCurrentTraining = computed(
			() =>
				personalTraining.value.isCurrent &&
				personalTraining.value.currentWeek === props.programWeek &&
				personalTraining.value.currentDay === props.programDay,
		);

		const storage = useStorage<z.infer<typeof StorageSchema>>(
			"personal-training",
			{
				[props.memberId]: {
					[props.programId]: {
						[props.programWeek]: {
							[props.programDay]: props.trainingDay.oefeningen.reduce(
								(acc, exercise) => ({
									...acc,
									[exercise.index]: exercise.huidigeWaarde,
								}),
								{} as z.infer<typeof DaySchema>,
							),
						},
					},
				},
			},
			sessionStorage,
			{
				mergeDefaults: (storageValue, defaults) =>
					deepmerge<z.infer<typeof StorageSchema>>(defaults, storageValue),
				serializer: {
					read: (value) => {
						const parsed = StorageSchema.safeParse(JSON.parse(value));

						if (!value || !parsed.success) {
							return {};
						}

						return parsed.data;
					},
					write: JSON.stringify,
				},
				onError: logger.error,
			},
		);

		const currentStorage = computed(
			() => storage.value[props.memberId][props.programId][props.programWeek][props.programDay],
		);

		function handleVideoClick(exercise: Pick<IomodelsOefeningExtern, "videoUrl">) {
			const video = videoRef.value;
			if (!video) {
				return;
			}

			video.pause();
			video.removeAttribute("src");

			requestAnimationFrame(() => {
				video.setAttribute("src", exercise.videoUrl);
				video.play();

				if (!document.fullscreenElement) {
					if (video.requestFullscreen) {
						video.requestFullscreen();
						// @ts-expect-error Not typed fully
					} else if (video.msRequestFullscreen) {
						// @ts-expect-error Not typed fully
						video.msRequestFullscreen();
						// @ts-expect-error Not typed fully
					} else if (video.mozRequestFullScreen) {
						// @ts-expect-error Not typed fully
						video.mozRequestFullScreen();
						// @ts-expect-error Not typed fully
					} else if (video.webkitRequestFullscreen) {
						// @ts-expect-error Not typed fully
						video.webkitRequestFullscreen();
					}
				}
			});
		}

		function formatHerhalingen(
			exercise: Pick<IomodelsOefeningExtern, "herhalingen" | "herhalingenStringToevoeging">,
		) {
			switch (exercise.herhalingenStringToevoeging?.toLowerCase()) {
				case "herhalingen":
					return [exercise.herhalingen, "×"].filter(Boolean).join("");
				case "min":
				case "minuten":
					return new Intl.NumberFormat("nl", { style: "unit", unit: "minute" }).format(
						exercise.herhalingen,
					);
				case "sec":
				case "seconden":
					return new Intl.NumberFormat("nl", { style: "unit", unit: "second" }).format(
						exercise.herhalingen,
					);
				default:
					return [exercise.herhalingen, exercise.herhalingenStringToevoeging]
						.filter(Boolean)
						.join(" ");
			}
		}

		async function submit() {
			if (!isCurrentTraining.value || !(await root.$validator.validateAll())) {
				toast.error("Niet mogelijk om training af te ronden");
				return;
			}

			await toast
				.promise(
					mutateAsync({
						trainingsProgrammaId: props.programId,
						week: props.programWeek,
						dag: props.programDay,
						voortgang: Object.entries(currentStorage.value)
							.map(([index, waarde]) =>
								typeof waarde === "number"
									? {
											index: Number(index),
											waarde: Number(waarde),
										}
									: undefined,
							)
							.filter(Boolean),
					}),
					{
						loading: "Training afronden...",
						success: "Training afgerond",
						error: formatErrorMessage,
					},
				)
				?.unwrap();
		}

		async function skip() {
			await toast
				.promise(
					mutateAsync({
						trainingsProgrammaId: props.programId,
						week: props.programWeek,
						dag: props.programDay,
						voortgang: [],
					}),
					{
						loading: "Training overslaan...",
						success: "Training overgeslagen",
						error: formatErrorMessage,
					},
				)
				?.unwrap();
		}

		const disabled = computed(() => isLoading.value);

		return {
			props,
			buttonVariants,
			inputVariants,
			cx,
			videoRef,
			disabled,
			isCurrentTraining,
			storage,
			currentStorage,
			handleVideoClick,
			formatHerhalingen,
			submit,
			skip,
		};
	},
});
