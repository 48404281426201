


















































































































































import { type PropType, computed, defineComponent } from "@vue/composition-api";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useQuery } from "@tanstack/vue-query";
import ErrorAlert from "@/components/ErrorAlert.vue";
import TrainingDayForm from "@/components/Schema/TrainingDayForm.vue";
import { pfgOutlineSchema } from "@/components/icons/pfg/outline/schema";
import { pfgSolidSchema } from "@/components/icons/pfg/solid/schema";
import ChevronLeftIcon from "@/lib/@heroicons/vue/16/solid/ChevronLeftIcon.vue";
import { usePersonalTrainingData } from "@/lib/hooks/usePersonalTrainingData";
import { buttonVariants } from "@/lib/pfg/components/button";
import { inputVariants } from "@/lib/pfg/components/input";
import { queryOptions_personalTrainingDay } from "@/lib/query/functions/personal-training/day";
import { cx } from "@/lib/style/cva.config";
import type { RouteProps } from "@/router/types";

type Props = RouteProps<"Member program">;

export default defineComponent({
	components: { FontAwesomeIcon, ErrorAlert, TrainingDayForm, ChevronLeftIcon },
	props: {
		memberId: {
			type: Number as PropType<Props["memberId"]>,
			required: true,
		},
		programId: {
			type: Number as PropType<Props["programId"]>,
			required: true,
		},
		programWeek: {
			type: Number as PropType<Props["programWeek"]>,
			required: true,
		},
		programDay: {
			type: Number as PropType<Props["programDay"]>,
			required: true,
		},
	},
	setup(props) {
		const personalTraining = usePersonalTrainingData(
			computed(() => props.memberId),
			computed(() => props.programId),
		);
		const { status, data, error } = useQuery(
			queryOptions_personalTrainingDay(
				computed(() => props.memberId),
				computed(() => props.programId),
				computed(() => props.programWeek),
				computed(() => props.programDay),
			),
		);

		const weekLabel = computed(
			() =>
				Intl.NumberFormat("nl", {
					style: "unit",
					unit: "week",
					unitDisplay: "long",
				})
					.formatToParts(1)
					.find((part) => part.type === "unit")?.value,
		);

		const dayLabel = computed(
			() =>
				Intl.NumberFormat("nl", {
					style: "unit",
					unit: "day",
					unitDisplay: "long",
				})
					.formatToParts(1)
					.find((part) => part.type === "unit")?.value,
		);

		return {
			props,
			pfgOutlineSchema,
			pfgSolidSchema,
			buttonVariants,
			inputVariants,
			cx,
			personalTraining,
			status,
			data,
			error,
			weekLabel,
			dayLabel,
		};
	},
});
