var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"flex flex-col flex-1 divide-y divide-gray-300",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_vm._l((_vm.props.trainingDay.oefeningen),function(exercise,index){return _c('div',{key:exercise.index,staticClass:"flex flex-col"},[(
				index === 0 ||
				(!!_vm.props.trainingDay.oefeningen[index - 1] &&
					_vm.props.trainingDay.oefeningen[index - 1].cardio !== exercise.cardio)
			)?[_c('h2',{class:_vm.cx('mb-4', index === 0 ? 'mt-2' : 'mt-8')},[(exercise.cardio)?[_vm._v("Cardio")]:[_vm._v("Workouts")]],2),_c('div',{staticClass:"grid gap-2 py-2 items-center text-sm font-medium border-0 border-solid border-b border-gray-300",attrs:{"data-type":exercise.cardio ? 'cardio' : 'workout'}},[_c('strong',[_vm._v("Oefening")]),(exercise.cardio)?[_c('strong',{staticClass:"text-center"},[_vm._v("Tijd")]),_c('strong',{staticClass:"text-center"},[_vm._v("Afgelegde afstand")])]:[_c('strong',{staticClass:"text-center"},[_vm._v("Aantal")]),_c('strong',{staticClass:"text-center"},[_vm._v("Sets")]),_c('strong',{staticClass:"text-center"},[_vm._v("KG")]),_c('strong',{staticClass:"text-center"},[_vm._v("Advies")])]],2)]:_vm._e(),_c('div',{staticClass:"grid py-2 gap-2 border-solid border-0 border-b border-gray-300",attrs:{"data-type":exercise.cardio ? 'cardio' : 'workout'}},[_c('button',{class:_vm.buttonVariants({ variant: 'naked', class: 'min-w-0 justify-start p-2 gap-2' }),attrs:{"type":"button"},on:{"click":function($event){return _vm.handleVideoClick(exercise)}}},[_c('PlayCircleIcon',{staticClass:"h-5 w-5 flex-shrink-0"}),_c('strong',{staticClass:"font-medium truncate"},[_vm._v(_vm._s(exercise.naam))])],1),_c('span',{staticClass:"tabular-nums text-center content-center"},[_vm._v(" "+_vm._s(_vm.formatHerhalingen(exercise))+" ")]),(!exercise.cardio)?_c('span',{staticClass:"tabular-nums text-center content-center"},[_vm._v(" "+_vm._s(exercise.sets)+" ")]):_vm._e(),((!_vm.isCurrentTraining && !!exercise.huidigeWaarde ? 'text' : 'number')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentStorage[exercise.index]),expression:"currentStorage[exercise.index]"}],class:_vm.inputVariants({
						class: 'text-center tabular-nums text-base m-0 transition text-gray-900',
					}),attrs:{"readonly":!_vm.isCurrentTraining && !!exercise.huidigeWaarde,"disabled":_vm.disabled || (!_vm.isCurrentTraining && !exercise.huidigeWaarde),"min":0,"step":0.1,"inputmode":"decimal","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.currentStorage[exercise.index])?_vm._i(_vm.currentStorage[exercise.index],null)>-1:(_vm.currentStorage[exercise.index])},on:{"change":function($event){var $$a=_vm.currentStorage[exercise.index],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.currentStorage, exercise.index, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.currentStorage, exercise.index, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.currentStorage, exercise.index, $$c)}}}}):((!_vm.isCurrentTraining && !!exercise.huidigeWaarde ? 'text' : 'number')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentStorage[exercise.index]),expression:"currentStorage[exercise.index]"}],class:_vm.inputVariants({
						class: 'text-center tabular-nums text-base m-0 transition text-gray-900',
					}),attrs:{"readonly":!_vm.isCurrentTraining && !!exercise.huidigeWaarde,"disabled":_vm.disabled || (!_vm.isCurrentTraining && !exercise.huidigeWaarde),"min":0,"step":0.1,"inputmode":"decimal","type":"radio"},domProps:{"checked":_vm._q(_vm.currentStorage[exercise.index],null)},on:{"change":function($event){return _vm.$set(_vm.currentStorage, exercise.index, null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentStorage[exercise.index]),expression:"currentStorage[exercise.index]"}],class:_vm.inputVariants({
						class: 'text-center tabular-nums text-base m-0 transition text-gray-900',
					}),attrs:{"readonly":!_vm.isCurrentTraining && !!exercise.huidigeWaarde,"disabled":_vm.disabled || (!_vm.isCurrentTraining && !exercise.huidigeWaarde),"min":0,"step":0.1,"inputmode":"decimal","type":!_vm.isCurrentTraining && !!exercise.huidigeWaarde ? 'text' : 'number'},domProps:{"value":(_vm.currentStorage[exercise.index])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentStorage, exercise.index, $event.target.value)}}}),(!exercise.cardio)?_c('button',{class:_vm.buttonVariants({
						variant: 'naked',
						class:
							'tabular-nums text-center content-center text-base font-normal disabled:opacity-100',
					}),attrs:{"type":"button","disabled":_vm.disabled || !_vm.isCurrentTraining || !exercise.adviesGewicht},on:{"click":function($event){_vm.currentStorage[exercise.index] = exercise.adviesGewicht}}},[_vm._v(" "+_vm._s(exercise.adviesGewicht)+" ")]):_vm._e()])],2)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCurrentTraining),expression:"isCurrentTraining"}],staticClass:"flex flex-col gap-2 sticky bottom-0 p-4 -m-4 mt-8 justify-end flex-1 bg-white"},[_c('button',{class:_vm.buttonVariants({
					variant: 'default',
					class: 'h-auto rounded-2xl py-3 px-6 font-bold text-base',
				}),attrs:{"type":"submit","disabled":_vm.disabled}},[_vm._v(" Training afronden ")]),_c('button',{class:_vm.buttonVariants({
					variant: 'naked',
					class: 'h-auto rounded-2xl py-3 px-6 font-bold text-base',
				}),attrs:{"type":"button","disabled":_vm.disabled},on:{"click":_vm.skip}},[_vm._v(" Training overslaan ")])]),_c('video',{ref:"videoRef",staticClass:"fixed -bottom-full -right-full w-0 h-0"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }